var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(typeof _vm.activity.id === 'string' || _vm.activity.id >= 0),expression:"typeof activity.id === 'string' || activity.id >= 0"}],staticStyle:{"background-color":"#e3f2fd80"}},[_c('v-card-title',{staticClass:"text-h5 grey--text text--darken-2 mb-0",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"v-tabs-slider-wrapper white--text",staticStyle:{"height":"6px","bottom":"0","width":"48px"}},[_c('div',{staticClass:"v-tabs-slider"})]),_vm._v(" Visit Invitation ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updateRecord.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"pb-9"},[_c('v-row',[_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"1","offset-md":"1"}},[_c('p',{staticClass:"text-body-1",class:{ 'text-right my-2': _vm.$vuetify.breakpoint.mdAndUp, 'mb-0': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._v(" Emails "),_c('v-icon',_vm._g(_vm._b({staticClass:"pb-2",attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment-outline ")])]}}])},[_c('span',[_vm._v("Space or comma delimiters")])])],1)]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-combobox',{attrs:{"value":_vm.search.emails.filter(function (email) { return _vm.rules.test(email); }),"outlined":"","hide-details":"","dense":"","append-icon":"","multiple":"","chips":"","error":_vm.invalid,"search-input":_vm.search.input},on:{"update:searchInput":function($event){return _vm.$set(_vm.search, "input", $event)},"update:search-input":function($event){return _vm.$set(_vm.search, "input", $event)},"keyup":function (ev) { return [' ', ','].includes(ev.key) && _vm.appendEmail(); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click:close":function($event){return _vm.removeEmail(item)}}},'v-chip',attrs,false),[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])]}}])})],1),_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"1","offset-md":"1"}},[_c('p',{staticClass:"text-body-1",class:{ 'text-right my-2': _vm.$vuetify.breakpoint.mdAndUp, 'mb-0': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Subject: ")])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","required":"","rules":[function (v) { return !!v; }]},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1),_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"1","offset-md":"1"}},[_c('p',{staticClass:"text-body-1",class:{ 'text-right my-2': _vm.$vuetify.breakpoint.mdAndUp, 'mb-0': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Date: ")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateSince,"outlined":"","hide-details":"","dense":"","required":"","rules":[function (v) { return !!v; }],"readonly":"","prepend-inner-icon":"mdi-calendar-today"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.since.dateMenu),callback:function ($$v) {_vm.$set(_vm.since, "dateMenu", $$v)},expression:"since.dateMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"change":function($event){_vm.since.dateMenu = false}},model:{value:(_vm.since.dateValue),callback:function ($$v) {_vm.$set(_vm.since, "dateValue", $$v)},expression:"since.dateValue"}})],1)],1),_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"1"}},[_c('p',{staticClass:"text-body-1",class:{ 'text-right my-2': _vm.$vuetify.breakpoint.mdAndUp, 'mb-0': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Time: ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{ref:"sinceMenuTime",attrs:{"close-on-content-click":false,"min-width":"290px","max-width":"290px","nudge-right":40,"return-value":_vm.since.timeValue,"transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){return _vm.$set(_vm.since, "timeValue", $event)},"update:return-value":function($event){return _vm.$set(_vm.since, "timeValue", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","hide-details":"","dense":"","required":"","rules":[function (v) { return !!v; }],"readonly":"","prepend-inner-icon":"mdi-clock-outline","disabled":!_vm.since.dateValue},model:{value:(_vm.since.timeValue),callback:function ($$v) {_vm.$set(_vm.since, "timeValue", $$v)},expression:"since.timeValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.since.timeMenu),callback:function ($$v) {_vm.$set(_vm.since, "timeMenu", $$v)},expression:"since.timeMenu"}},[(_vm.since.timeMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","use-seconds":""},on:{"click:second":function($event){return _vm.$refs.sinceMenuTime.save(_vm.since.timeValue)}},model:{value:(_vm.since.timeValue),callback:function ($$v) {_vm.$set(_vm.since, "timeValue", $$v)},expression:"since.timeValue"}}):_vm._e()],1)],1),_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"1"}},[_c('p',{staticClass:"text-body-1",class:{ 'text-right my-2': _vm.$vuetify.breakpoint.mdAndUp, 'mb-0': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Until: ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{ref:"untilMenuTime",attrs:{"close-on-content-click":false,"min-width":"290px","max-width":"290px","nudge-right":40,"return-value":_vm.until.timeValue,"transition":"scale-transition","offset-y":""},on:{"update:returnValue":function($event){return _vm.$set(_vm.until, "timeValue", $event)},"update:return-value":function($event){return _vm.$set(_vm.until, "timeValue", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","hide-details":"","dense":"","required":"","rules":[function (v) { return !!v; }],"readonly":"","prepend-inner-icon":"mdi-clock-outline","disabled":!_vm.since.dateValue},model:{value:(_vm.until.timeValue),callback:function ($$v) {_vm.$set(_vm.until, "timeValue", $$v)},expression:"until.timeValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.until.timeMenu),callback:function ($$v) {_vm.$set(_vm.until, "timeMenu", $$v)},expression:"until.timeMenu"}},[(_vm.until.timeMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","use-seconds":""},on:{"click:second":function($event){return _vm.$refs.untilMenuTime.save(_vm.until.timeValue)}},model:{value:(_vm.until.timeValue),callback:function ($$v) {_vm.$set(_vm.until, "timeValue", $$v)},expression:"until.timeValue"}}):_vm._e()],1)],1),_c('v-col',{class:{ 'pb-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"1","offset-md":"1"}},[_c('p',{staticClass:"text-body-1",class:{ 'text-right my-2': _vm.$vuetify.breakpoint.mdAndUp, 'mb-0': _vm.$vuetify.breakpoint.smAndDown }},[_vm._v(" Message: ")])]),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-textarea',{attrs:{"outlined":"","hide-details":"","dense":"","required":"","rules":[function (v) { return !!v; }],"rows":_vm.$vuetify.breakpoint.smAndDown ? 4 : 2},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"11"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"large":"","color":"primary","depressed":"","type":"submit"}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"large":"","color":"primary","depressed":"","outlined":""},on:{"click":function($event){_vm.activity.id = -1}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }