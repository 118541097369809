<template>
   <v-expand-transition>
      <div v-show="typeof activity.id === 'string' || activity.id >= 0" style="background-color: #e3f2fd80;">
         <v-card-title class="text-h5 grey--text text--darken-2 mb-0" style="position: relative;">
            <div class="v-tabs-slider-wrapper white--text" style="height: 6px; bottom: 0; width: 48px;">
               <div class="v-tabs-slider" />
            </div>
            Visit Invitation
         </v-card-title>
         <v-form ref="form" @submit.prevent="updateRecord">
            <v-card-text class="pb-9">
               <v-row>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        <!-- Emails: -->
                        <v-tooltip top>
                           <template v-slot:activator="{ on, attrs }">
                              <!-- <span v-bind="attrs" v-on="on">
                                 Emails
                              </span> -->
                              Emails
                              <v-icon x-small class="pb-2" v-bind="attrs" v-on="on">
                                 mdi-comment-outline
                              </v-icon>
                           </template>
                           <span>Space or comma delimiters</span>
                        </v-tooltip>
                     </p>
                  </v-col>
                  <v-col cols="12" md="9">
                     <v-combobox :value="search.emails.filter(email => rules.test(email))" outlined hide-details dense append-icon multiple chips
                        :error="invalid"
                        :search-input.sync="search.input"
                        @keyup="(ev) => [' ', ','].includes(ev.key) && appendEmail()"
                        >
                        <template v-slot:selection="{ attrs, item, selected }">
                           <v-chip v-bind="attrs" :input-value="selected" close @click:close="removeEmail(item)">
                              <span>
                              {{ item }}
                              </span>
                           </v-chip>
                        </template>
                     </v-combobox>
                  </v-col>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Subject:
                     </p>
                  </v-col>
                  <v-col cols="12" md="9">
                     <v-text-field v-model="form.subject" outlined hide-details dense required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Date:
                     </p>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-menu v-model="since.dateMenu" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field :value="dateSince" outlined hide-details dense required :rules="[v => !!v]" readonly
                              prepend-inner-icon="mdi-calendar-today" v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-date-picker v-model="since.dateValue" :first-day-of-week="1" @change="since.dateMenu = false" />
                     </v-menu>
                  </v-col>
                  <v-col cols="12" md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Time:
                     </p>
                  </v-col>
                  <v-col cols="12" md="2">
                     <v-menu ref="sinceMenuTime" v-model="since.timeMenu" :close-on-content-click="false" min-width="290px" max-width="290px"
                        :nudge-right="40" :return-value.sync="since.timeValue"
                        transition="scale-transition" offset-y
                        >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="since.timeValue" outlined hide-details dense required :rules="[v => !!v]" readonly
                              prepend-inner-icon="mdi-clock-outline" :disabled="!since.dateValue"
                              v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-time-picker v-if="since.timeMenu" v-model="since.timeValue" full-width format="24hr" use-seconds @click:second="$refs.sinceMenuTime.save(since.timeValue)" />
                     </v-menu>
                  </v-col>
                  <v-col cols="12" md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Until:
                     </p>
                  </v-col>
                  <v-col cols="12" md="2">
                     <v-menu ref="untilMenuTime" v-model="until.timeMenu" :close-on-content-click="false" min-width="290px" max-width="290px"
                        :nudge-right="40" :return-value.sync="until.timeValue"
                        transition="scale-transition" offset-y
                        >
                        <template v-slot:activator="{ on, attrs }">
                           <v-text-field v-model="until.timeValue" outlined hide-details dense required :rules="[v => !!v]" readonly
                              prepend-inner-icon="mdi-clock-outline" :disabled="!since.dateValue"
                              v-bind="attrs" v-on="on"
                              />
                        </template>
                        <v-time-picker v-if="until.timeMenu" v-model="until.timeValue" full-width format="24hr" use-seconds @click:second="$refs.untilMenuTime.save(until.timeValue)" />
                     </v-menu>
                  </v-col>
                  <v-col cols="12" md="1" offset-md="1" :class="{ 'pb-0': $vuetify.breakpoint.smAndDown }">
                     <!-- class="hidden-md-and-up pb-0" -->
                     <p class="text-body-1" :class="{ 'text-right my-2': $vuetify.breakpoint.mdAndUp, 'mb-0': $vuetify.breakpoint.smAndDown }">
                        Message:
                     </p>
                  </v-col>
                  <v-col cols="12" md="9">
                     <!-- offset-md="2" -->
                     <v-textarea v-model="form.message" outlined hide-details dense required :rules="[v => !!v]" :rows="$vuetify.breakpoint.smAndDown ? 4 : 2" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="11" class="d-flex justify-end">
                     <v-btn large color="primary" depressed class="ml-4" type="submit">
                        <!-- @click="updateRecord" -->
                        Submit
                     </v-btn>
                     <v-btn large color="primary" depressed outlined class="ml-4" @click="activity.id = -1">
                        Cancel
                     </v-btn>
                  </v-col>
               </v-row>
            </v-card-text>
         </v-form>
      </div>
   </v-expand-transition>
</template>

<script>
import { invitation } from '@/utils/fields.js'
export default {
   name: 'NInvitationForm',

   props: {
      activity: { type: Object },
      operator: { type: Object },
   },

   data: () => ({
      isDebugging: false,
      isUsed: false,
      // eslint-disable-next-line no-useless-escape
      rules: /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/,
      form: Object.assign({}, invitation),
      search: { input: null, email: {}, emails: [] },
      invalid: null,
      since: {
         dateMenu: false,
         dateValue: null,
         timeMenu: false,
         timeValue: null,
      },
      until: {
         timeMenu: false,
         timeValue: null,
      },
   }),

   computed: {
      dateSince () {
         return this.since.dateValue ? this.$moment(this.since.dateValue).format('dddd, YYYY-MM-DD') : ''
      },
   },
   watch: {
      'activity.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         id === 0 && (this.invalid = null)
         // this.form = Object.assign({}, invitation, id > 0 ? this.activity : { id: 0 })
         this.search.emails = []
         // if (id !== 0) {
         //    this.search.emails = this.activity.Emails.map(j => j.address)
         // }
         this.since.dateValue = ''
         if (id === 0 && this.isDebugging) {
            // below only for testing
            const { form, since, until } = this // , activity, search, select
            Object.assign(form, invitation, { id: 0, subject: 'Meeting Invitation', message: 'Don\'t ever late' })
            this.search.emails = ['jessica.nirtara@gmail.com', 'teresa.maculata@gmail.com']
            const now = this.$moment().add((30 - (this.$moment().minute() % 30)), 'minutes')
            since.dateValue = now.format('YYYY-MM-DD')
            since.timeValue = now.format('HH:mm:00')
            until.timeValue = now.add(3, 'hours').format('HH:mm:00')
         }
      },
   },
   mounted () {
   },
   methods: {
      appendEmail () {
         if (!this.search.input) return
         const { search } = this
         this.$nextTick(() => {
            const emails = search.input.split(/\r?\n|,| /).map(s => s.trim()).filter(email => {
               const idx = search.emails.findIndex(e => e === email)
               return idx >= 0 ? false : this.rules.test(email)
            })
            search.emails.push(...emails)
            this.$nextTick(() => { search.input = null; this.invalid = false })
         })
      },
      removeEmail (item) {
         this.search.emails.splice(this.search.emails.findIndex(s => s === item), 1)
         this.invalid = !this.search.emails.length
      },
      updateRecord () {
         this.invalid = !this.$refs.form.validate() || !this.search.emails.length
         if (this.invalid) return
         // const ipV4 = this.$store.state.ipV4 || (await (await fetch('https://api.ipify.org?format=json')).json()).ip
         const { since, until } = this
         const form = Object.assign({}, this.form, {
            name: this.operator.Organizations.find(j => j.identity === this.operator.organization).name,
            zone: this.operator.zone,
            Emails: this.search.emails.map(s => { return { Email: [], address: s } }),
            activity: 'Enrolled',
            Activities: [{ Activity: [], name: 'Enrolled', since: this.$moment().format('YYYY-MM-DD HH:mm:ss'), operator: this.operator.identity || 'None', ipV4: this.$store.state.ipV4 }],
            valid: since.dateValue ? (since.timeValue ? `${since.dateValue} ${since.timeValue}` : `${since.dateValue} 00:00:00`) : this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            until: since.dateValue ? (until.timeValue ? `${since.dateValue} ${until.timeValue}` : `${since.dateValue} 23:59:59`) : `${this.$moment().format('YYYY-MM-DD')} 23:59:59`,
         })
         // console.log('updateRecord (form)', JSON.parse(JSON.stringify(form)))
         this.$emit('update', form)
      },
   },
}
</script>

<style lang="scss" scoped>
// .theme--light.v-list {
//    background: #e3f2fd;
// }
// ::v-deep .v-list-item .v-list-item__title {
//    font-size: 0.925rem;
//    line-height: 1.1rem;
// }
.v-btn:not(.v-btn--round).v-size--large {
   height: 44px;
   min-width: 150px;
}
::v-deep .v-select.v-input--dense .v-chip {
   margin: 2px;
   border-radius: 6px;
   background-color: #e3f2fd;
}
::v-deep .v-chip.v-size--default {
   height: 28px;
}
// .theme--light.v-btn.v-btn--icon {
//    height: 38px;
//    border-radius: 0 3px 3px 0;
//    min-width: 80px;
//    margin-right: -11px;
//    margin-top: -7px;
//    background-color: #01579b;
//    color: #fefefe;
// }
</style>
